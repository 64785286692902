import axios from 'axios'
import Qs from 'qs'
import * as moment from 'moment'

export default {
  user_orders(options) {
    return axios
      .get('/api/admin/orders/user_orders', {
        params: {
          group_ids: options.groups.map(g => g.id),
          user_ids: options.users.map(u => u.id),
          order_types: options.orderTypes.map(ot => ot.value),
          start_date: options.startDate,
          end_date: options.endDate
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets',
            serializeDate: date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
          })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
