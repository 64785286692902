import axios from 'axios'
import Qs from 'qs'

export default {
  groups() {
    return axios
      .get('/api/admin/users/groups', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  group_users(groupIDs) {
    return axios
      .get('/api/admin/users/group_users', {
        params: {
          users: {
            group_ids: groupIDs
          }
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  reInviteUser(userID) {
    return axios
      .get(`/api/admin/users/${userID}/reinvite_user`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  },
  reInvitePendingUsers() {
    return axios
      .get(`/api/admin/users/reinvite_pending_users`, {
        params: {},
        handleErrors: false
      })
      .then(
        res => {
          return res.data
        },
        error => {
          return Promise.reject(error.response.data)
        }
      )
  }
}
