<template>
  <admin-page-card
    pageName="User Order Report"
    iconClass="fa fa-shopping-cart fa-lg"
  >
    <purchase-history
      role="admin"
      :groupsList="groupsList"
      :usersList="usersList"
      :orderReports="orderReports"
      :scheduledReport="scheduledReport"
      :fetchingGroups="fetchingGroups"
      :fetchingUsers="fetchingUsers"
      :fetchingReports="fetchingReports"
      :updatingScheduledReport="updatingScheduledReport"
      @fetchGroupUsers="getGroupUsers"
      @fetchPurchaseHistory="getPurchaseHistory"
      @enableMonthlyReport="scheduleMonthlyReport"
      @updateMonthlyReport="updateScheduledReport"
      @deleteMonthlyReport="deleteScheduledReport"
    ></purchase-history>
  </admin-page-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'
// ui components
import UserPurchaseHistory from '@/shared/components/UserPurchaseHistory'
// api
import usersAPI from '@/api/finapps/admin/users'
import ordersAPI from '@/api/finapps/admin/orders'
import userOrderReportsAPI from '@/api/finapps/admin/user_order_reports'

export default {
  name: 'AdminUserPurchaseHistory',
  components: {
    'purchase-history': UserPurchaseHistory
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/manageAdminBoard']) {
      next()
    } else {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.getUserGroups()
    this.getUserOrderReport()
  },
  data() {
    return {
      groupsList: [],
      usersList: [],
      orderReports: [],
      scheduledReport: null,
      fetchingUsers: false,
      fetchingGroups: false,
      fetchingReports: false,
      updatingScheduledReport: false
    }
  },
  computed: {
    ...mapGetters('Ability', ['manageSuperAdminBoard'])
  },
  methods: {
    getUserOrderReport() {
      this.updatingScheduledReport = true
      userOrderReportsAPI.user_report().then(res => {
        this.scheduledReport = res.report
        this.updatingScheduledReport = false
      })
    },
    getUserGroups() {
      this.fetchingGroups = true
      usersAPI.groups().then(res => {
        this.groupsList = res.groups
        this.fetchingGroups = false
      })
    },
    getGroupUsers(groups) {
      this.fetchingUsers = true
      let groupIDs = groups.map(g => g.id)
      usersAPI.group_users(groupIDs).then(res => {
        this.usersList = res.users
        this.fetchingUsers = false
      })
    },
    getPurchaseHistory(options) {
      this.fetchingReports = true
      ordersAPI.user_orders(options).then(res => {
        this.orderReports = res.orders
        this.fetchingReports = false
      })
    },
    scheduleMonthlyReport(filters) {
      this.updatingScheduledReport = true
      userOrderReportsAPI.create(filters).then(res => {
        this.scheduledReport = res.report
        this.$toasted.show('Montly report scheduled successfully.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.updatingScheduledReport = false
      })
    },
    updateScheduledReport(filters) {
      this.updatingScheduledReport = true
      userOrderReportsAPI.update(filters).then(res => {
        this.scheduledReport = res.report
        this.$toasted.show('Montly report scheduled successfully.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.updatingScheduledReport = false
      })
    },
    deleteScheduledReport(reportID) {
      this.updatingScheduledReport = true
      userOrderReportsAPI.delete(reportID).then(res => {
        this.scheduledReport = res.report
        this.$toasted.show('Successfully removed scheduled montly report.', {
          icon: 'user-circle',
          type: 'success'
        })
        this.updatingScheduledReport = false
      })
    }
  }
}
</script>
