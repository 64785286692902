import axios from 'axios'

export default {
  user_report() {
    return axios
      .get('/api/admin/user_order_reports/user_report', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  create(options) {
    return axios
      .post(
        '/api/admin/user_order_reports',
        {
          group_ids: options.groups.map(g => g.id),
          user_ids: options.users.map(u => u.id),
          order_types: options.orderTypes.map(type => type.value)
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  update(options) {
    return axios
      .put(
        `/api/admin/user_order_reports/${options.id}`,
        {
          group_ids: options.groups.map(g => g.id),
          user_ids: options.users.map(u => u.id),
          order_types: options.orderTypes.map(type => type.value)
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  delete(reportID) {
    return axios
      .delete(`/api/admin/user_order_reports/${reportID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
